// Canvas has to be 48px x 48px
export const ICONS = {
    PHONE:
        "M15.0156 21.9688C17.5156 26.8125 21.1875 30.4844 26.0312 32.9844L29.7031 29.3125C30.224 28.7917 30.7969 28.6615 31.4219 28.9219C33.2969 29.5469 35.276 29.8594 37.3594 29.8594C37.8281 29.8594 38.2187 30.0156 38.5312 30.3281C38.8438 30.6406 39 31.0312 39 31.5V37.3594C39 37.8281 38.8438 38.2187 38.5312 38.5312C38.2187 38.8438 37.8281 39 37.3594 39C29.5468 39 22.8672 36.2266 17.3203 30.6797C11.7734 25.1328 9 18.4532 9 10.6406C9 10.1719 9.15625 9.78125 9.46875 9.46875C9.78125 9.15625 10.1719 9 10.6406 9H16.5C16.9688 9 17.3594 9.15625 17.6719 9.46875C17.9844 9.78125 18.1406 10.1719 18.1406 10.6406C18.1406 12.724 18.4531 14.7031 19.0781 16.5781C19.2865 17.2552 19.1563 17.8281 18.6875 18.2969L15.0156 21.9688Z",

    WARNING:
        "M23.8855 47C21.4584 47 19.378 45.0352 19.378 42.4925C19.378 40.0653 21.3428 37.9849 23.8855 37.9849C26.3127 37.9849 28.3931 39.9497 28.3931 42.4925C28.3931 44.9196 26.3127 47 23.8855 47ZM19.0312 5.85427C19.0312 3.19598 21.2272 1 23.8855 1C26.5438 1 28.7398 3.19598 28.7398 5.85427V25.1558C28.7398 27.8141 26.5438 30.01 23.8855 30.01C21.2272 30.01 19.0312 27.8141 19.0312 25.1558V5.85427Z",

    CHECKMARK:
        "M7.8835 23.1456L16.4272 31.6893L40.1165 8L44 11.8835L16.4272 39.4563L4 27.0291L7.8835 23.1456Z",

    CHEVRON:
        "M2.58581 13.5858C3.3669 12.8047 4.63329 12.8047 5.41437 13.5858L23.8 31.9714L42.1856 13.5858C42.9667 12.8047 44.2331 12.8047 45.0142 13.5858C45.7953 14.3669 45.7953 15.6333 45.0142 16.4144L25.2143 36.2143C24.4332 36.9954 23.1668 36.9954 22.3857 36.2143L2.58581 16.4144C1.80473 15.6333 1.80473 14.3669 2.58581 13.5858Z",
    STAR:
        "M24 36.5681L10.3662 44.831L13.9812 29.338L2 18.9061L17.8028 17.5634L24 3L30.1972 17.5634L46 18.9061L34.0188 29.338L37.6338 44.831L24 36.5681Z",
    LOCATION:
        "M24.28 22.7152C25.7841 22.7152 27.0659 22.1853 28.1256 21.1256C29.1853 20.0659 29.7152 18.7841 29.7152 17.28C29.7152 15.7759 29.1853 14.4941 28.1256 13.4344C27.0659 12.3747 25.7841 11.8448 24.28 11.8448C22.7759 11.8448 21.4941 12.3747 20.4344 13.4344C19.3747 14.4941 18.8448 15.7759 18.8448 17.28C18.8448 18.7841 19.3747 20.0659 20.4344 21.1256C21.4941 22.1853 22.7759 22.7152 24.28 22.7152ZM24.28 2C28.5188 2 32.1251 3.48696 35.0991 6.46094C38.073 9.43491 39.56 13.0412 39.56 17.28C39.56 19.3994 39.0302 21.8264 37.9705 24.5611C36.9108 27.2958 35.6289 29.8595 34.1248 32.2524C32.6208 34.6452 31.1338 36.8842 29.6639 38.9694C28.194 41.0546 26.9463 42.7125 25.9208 43.9431L24.28 45.6864C23.8698 45.2079 23.3229 44.5755 22.6392 43.7893C21.9555 43.003 20.7249 41.4306 18.9474 39.0719C17.1698 36.7133 15.6145 34.423 14.2813 32.2011C12.9482 29.9791 11.7347 27.4667 10.6408 24.6636C9.54693 21.8606 9 19.3994 9 17.28C9 13.0412 10.487 9.43491 13.4609 6.46094C16.4349 3.48696 20.0412 2 24.28 2Z",
    MAIL:
        "M36.0423 18.0563V15.0282L24 22.5634L11.9577 15.0282V18.0563L24 25.5211L36.0423 18.0563ZM36.0423 12C36.8404 12 37.5329 12.3052 38.1197 12.9155C38.7066 13.5258 39 14.23 39 15.0282V33.0563C39 33.8545 38.7066 34.5587 38.1197 35.169C37.5329 35.7793 36.8404 36.0845 36.0423 36.0845H11.9577C11.1596 36.0845 10.4671 35.7793 9.88028 35.169C9.29342 34.5587 9 33.8545 9 33.0563V15.0282C9 14.23 9.29342 13.5258 9.88028 12.9155C10.4671 12.3052 11.1596 12 11.9577 12H36.0423Z",
    CLOSE:
        "M43 8.84071L39.1593 5L24 20.1593L8.84071 5L5 8.84071L20.1593 24L5 39.1593L8.84071 43L24 27.8407L39.1593 43L43 39.1593L27.8407 24L43 8.84071Z",
}
